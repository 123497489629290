import PropTypes from 'prop-types';
import { useSiteContext } from './context/site-context';

export default function SiteView({ children }) {
  const {site, sites} = useSiteContext();

  return  site ? children : <div>サイトを選択してください。</div>
}

SiteView.propTypes = {
  children: PropTypes.node,
};