import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getSites } from '../../../cms-api/user_acl';
import { getList, get } from '../../../cms-api/site';
// import { getPageList } from '../../../cms-api/page';
import { SiteContext } from './site-context';
import { getSiteConfig } from './site-config';
import { SplashScreen } from '../../loading-screen';
import { useParams, useRouter } from '../../../routes/hook';
import { getSiteData } from '../../../utils/site';
import { paths } from '../../../routes/paths';

export function SiteProvider({children}) {

  const router = useRouter();

  const params = useParams();
  const { site_code } = params;

  const [sites, setSites] = useState([]);

  const [site, setSiteState] = useState(null);

  const [siteConfig, setSiteConfig] = useState(null);

  const [loading, setLoading] = useState(true);

  const setSite = useCallback(async (newSite) => {

    setLoading(true);

    const oldSiteId = localStorage.getItem('site_id');

    let isPush = false;
    if (!newSite) {
      isPush = true;
    } else if (!oldSiteId) {
      isPush = true;
    } else if (newSite.site_id !== oldSiteId) {
      isPush = true;
    }

    if (newSite) {
      localStorage.setItem('site_id', newSite.site_id);

      const { siteData, configData } = await getSiteData(newSite.site_id);

      setSiteState(siteData);
      setSiteConfig(configData);
      setLoading(false);

    } else {
      localStorage.removeItem('site_id');
      setSiteState(null);
      setSiteConfig({});
      setLoading(false);
    }

    if (isPush) {
      if (newSite) {
        router.push(paths.dashboard.site.root(newSite.code));
      } else {
        // router.push(paths.dashboard.root);
      }
    }

  }, [router]);

  useEffect(() => {
    getSites().then((res) => {
      if (res.site_ids?.length > 0) {
        getList({
          site_ids: res.site_ids,
        }).then((resAll) => {
          setSites(resAll.site_list);

          if (!site) {
            if (site_code) {
              const getSite = resAll.site_list.find((fsite) => fsite.code === site_code);
              setSite(getSite);
            } else if (resAll.site_list.length > 0) {
              const defSiteId = localStorage.getItem('site_id')

              if (defSiteId) {
                const defSite = resAll.site_list.find((fsite) => fsite.site_id === defSiteId);
                if (defSite) {
                  // setSite(defSite);
                  router.push(paths.dashboard.site.root(defSite.code));
                } else {
                  // setSite(resAll.site_list[0])
                  router.push(paths.dashboard.site.root(resAll.site_list[0].code));
                }
              } else {
                router.push(paths.dashboard.site.root(resAll.site_list[0].code));
                // setSite(resAll.site_list[0])
              }

            } else if (resAll.site_list.length === 0) {
              setSite(null)
            }
          }
        });
      } else {
        setSite(null)
      }
    });
  }, [site, setSite, setSiteConfig, site_code, router])

  const memoizedValue = useMemo(
    () => ({
      site,
      siteConfig,
      sites,
      loading,
      setSite,
      setSiteConfig,
    }),
    [site, sites, loading, setSite, siteConfig, setSiteConfig]
  );

  return <SiteContext.Provider value={memoizedValue}>{ loading ? <SplashScreen /> : children }</SiteContext.Provider>;
}

SiteProvider.propTypes = {
  children: PropTypes.node,
};
