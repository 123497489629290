import axios from 'axios';
import cookies from 'js-cookie';
import ApiError, {ApiIsLoginError, NotLoginCode} from './cms-errors'

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export async function httpGet(url, params = {}, headers = {}) {
  const api_key = cookies.get('api_key')
  if (!api_key) {
    const apiError = new ApiError("not login")
    apiError.ErrorCode = NotLoginCode
    throw apiError
  }

  const response = await axiosInstance.get(`/api${url}`, {
    params,
    headers,
  });

  const {status, error} = response.data;

  if (status !== 'OK') {
    if (ApiIsLoginError(error)) {
      const apiError = new ApiError("not login")
      apiError.ErrorCode = NotLoginCode
      throw apiError
    }
    throw new Error(error);
  }

  return response.data
}

export async function httpPost(url, data = {}, headers = {}) {
  const api_key = cookies.get('api_key')
  if (!api_key) {
    const apiError = new ApiError("not login")
    apiError.ErrorCode = NotLoginCode
    throw apiError
  }

  const response = await axiosInstance.post(`/api${url}`, data, {
    headers,
  });

  const {status, error} = response.data;

  if (status !== 'OK') {
    if (ApiIsLoginError(error)) {
      const apiError = new ApiError("not login")
      apiError.ErrorCode = NotLoginCode
      throw apiError
    }
    throw new Error(error);
  }

  return response.data
}

