import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSiteContext } from '../../context/site-context';
import { useParams } from '../../../../routes/hook';
import { PostContext } from './post-context';
import { getType } from '../../../../cms-api/post';

export function PostProvider({children}) {
  const {site, siteConfig} = useSiteContext();

  const {post_type_id} = useParams();

  const [postType, setPostType] = useState(null);

  const [postTypeConfig, setPostTypeConfig] = useState(null);

  const [isLoading, setLoading] = useState(true);


  useEffect(() => {

    setLoading(true)

    let isDel = true;

    if (siteConfig && post_type_id) {
      getType({
        site_id: site.site_id,
        type: post_type_id,
      }).then((reData) => {
        const postConfig = siteConfig.posts.find((post) => post.id === post_type_id);
        if (postConfig) {
          setPostType(reData)
          setPostTypeConfig(postConfig)
          isDel = false
        }

        if (isDel) {
          setPostType(null);
          setPostTypeConfig(null);
        }

        setLoading(false)
      })
    }

  }, [site, post_type_id, siteConfig, setPostType, setPostTypeConfig, setLoading]);

  const memoizedValue = useMemo(
    () => ({
      postType,
      postTypeConfig,
      postTypeIsLoading: isLoading,
    }),
    [postType, postTypeConfig, isLoading]
  );

  return (
    <PostContext.Provider value={memoizedValue}>
      {children}
    </PostContext.Provider>
  );
}

PostProvider.propTypes = {
  children: PropTypes.node,
};