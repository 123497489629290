import useSWR from 'swr';
import { useMemo } from 'react';

import { httpGet, httpPost } from 'src/utils/cms-axios';

export function get(data) {

  const { site_id } = data

  return httpGet("/site", {}, {"cms-site-id": site_id});
}

export function getList(data) {

  const { site_ids } = data

  return httpPost("/site/list", {
    "site_ids": site_ids,
  });
}

export function update(data) {

  const { site_id, name, domain, expand_value } = data

  return httpPost("/site/update", {
    "name": name,
    "domain": domain,
    "expand_value": expand_value,
  }, {
    "cms-site-id": site_id,
  });
}

export function createAutoCert(data) {

  const { site_id, domain } = data

  return httpPost("/site/create-auto-cert", {
    "domain": domain,
  }, {
    "cms-site-id": site_id,
  });
}

export function GetConfig(data) {

  const { site_id } = data

  return httpGet("/site/config", {}, {
    "cms-site-id": site_id,
  });
}

// update config
export function UpdateConfig(data) {

  const { site_id, digest_user, digest_pass, front_api_digest_user,
    front_api_digest_pass, mail_smtp_host, mail_smtp_user, mail_smtp_password,
    mail_smtp_port, mail_smtp_is_tls, shopify_url_path, recaptcha_key,
    recaptcha_score, recaptcha_enterprise_api_key, recaptcha_enterprise_project_id } = data

  return httpPost("/site/config/update", {
    "config": {
      digest_user,
      digest_pass,
      front_api_digest_user,
      front_api_digest_pass,
      mail_smtp_host,
      mail_smtp_user,
      mail_smtp_password,
      mail_smtp_port,
      mail_smtp_is_tls,
      shopify_url_path,
      recaptcha_key,
      recaptcha_score,
      recaptcha_enterprise_api_key,
      recaptcha_enterprise_project_id
    },
  }, {
    "cms-site-id": site_id,
  });
}

export function useGetSite(site_id) {

  const {data, isLoading, isError, isValidating, mutate, error} = useSWR({site_id}, get)

  const memoizedValue = useMemo(
    () => ({
      site: data || null,
      siteLoading: isLoading,
      siteError: error,
      siteValidating: isValidating,
      siteRefresh: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}
