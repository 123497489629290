import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const SiteContext = createContext({});

export const useSiteContext = () => {
  const context = useContext(SiteContext);

  if (!context) throw new Error('useSiteContext must be use inside SiteProvider');

  return context;
};
