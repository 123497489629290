import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const PostContext = createContext({});

export const usePostContext = () => {
  const context = useContext(PostContext);

  if (!context) throw new Error('usePostContext must be use inside PostProvider');

  return context;
};
