import useSWR from 'swr';
import { useMemo } from 'react';

import { httpGet, httpPost } from 'src/utils/cms-axios';

export function getUser() {
  return httpGet("/user");
}

export function useGetUser() {

  const { data, isLoading, error, isValidating } = useSWR({}, getUser);

  const memoizedValue = useMemo(
    () => ({
      user: data,
      userLoading: isLoading,
      userError: error,
      userValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function updateUser(data) {

  const { email, name, password, expand_value } = data

  return httpPost("/user/update", {
    email,
    name,
    password,
    expand_value,
  });
}
